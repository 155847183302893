export default defineAppConfig({
    ui: {
        primary: 'violet',
        gray: 'cool',
        tooltip: {
            default: {
                openDelay: 500,
            },
        },
    },
    // Fix build error "Property 'nuxtIcon' is missing in type 'AppConfig'" (https://github.com/nuxt/ui/issues/1045)
    nuxtIcon: {},
});
