import { default as dashboard_45demomhdFioofxYMeta } from "/opt/render/project/src/apps/admin/pages/dashboard-demo.vue?macro=true";
import { default as dossiersaD4psFmv4yMeta } from "/opt/render/project/src/apps/admin/pages/dossiers.vue?macro=true";
import { default as inboxnLaVjQ26HRMeta } from "/opt/render/project/src/apps/admin/pages/inbox.vue?macro=true";
import { default as indexzaCC7vTIAxMeta } from "/opt/render/project/src/apps/admin/pages/index.vue?macro=true";
import { default as loginy3TDq9ePx9Meta } from "/opt/render/project/src/apps/admin/pages/login.vue?macro=true";
import { default as indexeuhh8d7isvMeta } from "/opt/render/project/src/apps/admin/pages/settings/index.vue?macro=true";
import { default as members29urSlaPZSMeta } from "/opt/render/project/src/apps/admin/pages/settings/members.vue?macro=true";
import { default as notificationsAWZVIEP4o2Meta } from "/opt/render/project/src/apps/admin/pages/settings/notifications.vue?macro=true";
import { default as settingsScZH7MN4faMeta } from "/opt/render/project/src/apps/admin/pages/settings.vue?macro=true";
import { default as usersTTWD1YXuYoMeta } from "/opt/render/project/src/apps/admin/pages/users.vue?macro=true";
export default [
  {
    name: "dashboard-demo",
    path: "/dashboard-demo",
    component: () => import("/opt/render/project/src/apps/admin/pages/dashboard-demo.vue").then(m => m.default || m)
  },
  {
    name: "dossiers",
    path: "/dossiers",
    component: () => import("/opt/render/project/src/apps/admin/pages/dossiers.vue").then(m => m.default || m)
  },
  {
    name: "inbox",
    path: "/inbox",
    component: () => import("/opt/render/project/src/apps/admin/pages/inbox.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/render/project/src/apps/admin/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginy3TDq9ePx9Meta || {},
    component: () => import("/opt/render/project/src/apps/admin/pages/login.vue").then(m => m.default || m)
  },
  {
    name: settingsScZH7MN4faMeta?.name,
    path: "/settings",
    component: () => import("/opt/render/project/src/apps/admin/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "settings",
    path: "",
    component: () => import("/opt/render/project/src/apps/admin/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-members",
    path: "members",
    component: () => import("/opt/render/project/src/apps/admin/pages/settings/members.vue").then(m => m.default || m)
  },
  {
    name: "settings-notifications",
    path: "notifications",
    component: () => import("/opt/render/project/src/apps/admin/pages/settings/notifications.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "users",
    path: "/users",
    component: () => import("/opt/render/project/src/apps/admin/pages/users.vue").then(m => m.default || m)
  }
]