import revive_payload_client_YTGlmQWWT5 from "/opt/render/project/src/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/opt/render/project/src/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/opt/render/project/src/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VTarsGAzgb from "/opt/render/project/src/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/opt/render/project/src/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/opt/render/project/src/apps/admin/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/opt/render/project/src/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_j3xPeZV1re from "/opt/render/project/src/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import slideovers_oeUu8xcI5w from "/opt/render/project/src/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_qI5jN0JlD2 from "/opt/render/project/src/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_LeAKZ8VmiU from "/opt/render/project/src/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_VfWFs4sWWP from "/opt/render/project/src/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import scrollbars_client_3WrHJn1XBW from "/opt/render/project/src/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import chunk_reload_client_SeG0EjL5Ec from "/opt/render/project/src/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import presets_NBny7l3smW from "/opt/render/project/src/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_BKqLrMFqSU from "/opt/render/project/src/node_modules/@nuxt/ui-pro/plugins/variables.ts";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  payload_client_VTarsGAzgb,
  check_outdated_build_client_LIYcCMJD18,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  plugin_j3xPeZV1re,
  slideovers_oeUu8xcI5w,
  modals_qI5jN0JlD2,
  colors_LeAKZ8VmiU,
  plugin_client_VfWFs4sWWP,
  scrollbars_client_3WrHJn1XBW,
  chunk_reload_client_SeG0EjL5Ec,
  presets_NBny7l3smW,
  variables_BKqLrMFqSU
]