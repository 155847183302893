<script setup lang="ts">
import type { NuxtError } from '#app';

useSeoMeta({ title: 'Page not found - Fleequid Admin' });

defineProps({
    error: {
        type: Object as PropType<NuxtError>,
        required: true,
    },
});
</script>

<template>
    <div>
        <UMain>
            <UContainer>
                <UPage>
                    <UPageError :error="error" />
                </UPage>
            </UContainer>
        </UMain>

        <UNotifications />
    </div>
</template>
