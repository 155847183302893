<script setup lang="ts">
const colorMode = useColorMode();

const color = computed(() => (colorMode.value === 'dark' ? '#111827' : 'white'));

useHead({
    titleTemplate: title => {
        return title ? `${title} - Fleequid Admin` : 'Fleequid Admin';
    },
    meta: [{ key: 'theme-color', name: 'theme-color', content: color }],
});
</script>

<template>
    <div>
        <NuxtLoadingIndicator />

        <NuxtLayout>
            <NuxtPage />
        </NuxtLayout>

        <UNotifications />
        <UModals />
    </div>
</template>
